<template>
  <div class="container">
    <div class="d-flex align-items-center justify-content-between">
      <h3>{{ $t("categories") }}</h3>
      <div class="d-flex gap-2">
        <button @click="showNext" class="bg-transparent slider__icon">
          <b-icon icon="arrow-right"></b-icon>
        </button>
        <button @click="showPrev" class="bg-transparent slider__icon">
          <b-icon icon="arrow-left"></b-icon>
        </button>
      </div>
    </div>

    <div class="mt-3 categories">
      <VueSlickCarousel v-bind="settings" ref="carousel">
        <div v-for="(item, index) in products" :key="index">
          <div class="ms-3 mb-3 categories__item">
            <button
              class="d-flex align-items-center justify-content-center categories__item__btn"
              :class="{ isActive: getActiveItem(item) }"
              @click="$emit('fetchSubProducts', item._id)"
            >
              <!-- <div class="">
                <img
                  :src="$baseUploadURL + item.subCategoryImage"
                  :alt="item.name"
                  class="border-bottom"
                />
              </div> -->
              <span class="categories__item__text">
                {{ $getByLang(item.name) }}
              </span>
            </button>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel-rtl";
import "vue-slick-carousel-rtl/dist/vue-slick-carousel-rtl.css";
import "vue-slick-carousel-rtl/dist/vue-slick-carousel-theme.css";
export default {
  props: {
    products: {
      type: Array,
      required: true,
    },
    subCategoriesId: {
      type: Array,
    },
  },
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        rows: 1,
        cssEase: "linear",
        rtl: this.$i18n.locale === "ar" ? true : false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1.8,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2.2,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },

  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
    getActiveItem(item) {
      return this.subCategoriesId.includes(item._id);
    },
  },
};
</script>
<style lang="scss" scoped>
.slider__icon {
  font-size: 24px;
}
.categories {
  &__item {
    &__btn {
      width: 100%;
      padding: 8px 12px;
      border-radius: 8px;
      height: 60px;
      text-align: center;
      transition: all 0.3s;
      &:hover,
      &.isActive {
        background-color: #ffeece;
        color: #f59f00;
      }
    }
    &__text {
      color: #000;
    }
  }
}
</style>
